import AssistanceSection from '../AssistanceSection';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface IHeaderDataFieldGroupProps {
    fieldName: string;
    fields: any[];
    children: React.ReactNode;
    controls: React.ReactNode;
    title?: string;
}

export const useHeaderDataFieldGroupProps = ({ fieldName, fields, controls }: IHeaderDataFieldGroupProps) => {
    const { t } = useTranslation('assistance');

    return {
        title:
            fields?.find((field) => field.fieldName === fieldName)?.config?.fieldLabelName ??
            t(`assistance:headerView.fieldNames.${fieldName}`),
        required: fields?.every((field) => field.config?.isRequired),
        controls,
    };
};

export const HeaderDataFieldGroup = (props: IHeaderDataFieldGroupProps) => {
    const fieldGroupProps = useHeaderDataFieldGroupProps(props);
    return <AssistanceSection.FieldGroup {...fieldGroupProps}>{props.children}</AssistanceSection.FieldGroup>;
};

export default HeaderDataFieldGroup;
