import { gql } from '@apollo/client';
import axios from 'axios';
import { MASTERDATA_FILE_UPLOAD_URL } from './constants';
import { MasterdataConfig } from './types';
import { isArray } from 'lodash';

export const GET_PAGINATED_DATA_IMPORT_RUNS = gql`
    query PaginatedDataImportRuns($filters: [TableFilter], $offset: Int, $first: Int) {
        dataImportRuns(filters: $filters, offset: $offset, first: $first) {
            totalCount
            edges {
                node {
                    id
                    customerId
                    lookupType
                    lookupDefinitionId
                    status
                    isActiveVersion
                    trigger
                    inputFileUrl
                    inputFileSize
                    inputFileNumRows
                    inputFileName
                    inputFile2Url
                    inputFile2Size
                    inputFile2NumRows
                    inputFile2Name
                    inputFileUrl
                    ingestionFileUrl
                    ingestionFileName
                    ingestionFileSize
                    ingestionFileNumRows
                    errorType
                    errorMessage
                    errorMessageKwargs
                    createdAt
                    startedAt
                    finishedAt
                    djangoAdminUrl
                }
            }
        }
    }
`;

export const uploadMasterdataFiles = (files: any[], lookupDefinitionId: string | string[]) => {
    return axios.all(
        files.map((file) => {
            const formData = new FormData();
            formData.append(
                'lookup_definition_id',
                isArray(lookupDefinitionId) ? lookupDefinitionId.join(',') : lookupDefinitionId
            );
            formData.append('file_name', file.name);
            formData.append('file', file);

            return axios.post(MASTERDATA_FILE_UPLOAD_URL, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        })
    );
};

export const uploadArticleList = (files, masterDataConfig: MasterdataConfig) =>
    uploadMasterdataFiles(files, masterDataConfig.articleLookupDefinitionId);

export const uploadDebitorArticleList = (files, masterDataConfig: MasterdataConfig) =>
    uploadMasterdataFiles(files, masterDataConfig.debitorArticleLookupDefinitionId);

export const uploadClientList = (files, masterDataConfig: MasterdataConfig) =>
    uploadMasterdataFiles(files, masterDataConfig.clientLookupDefinitionId);

export const uploadReceiverList = (files, masterDataConfig: MasterdataConfig) =>
    uploadMasterdataFiles(files, masterDataConfig.receiverLookupDefinitionId);

export const uploadDeliveryAddressList = (files, masterDataConfig: MasterdataConfig) =>
    uploadMasterdataFiles(files, masterDataConfig.deliveryAddressLookupDefinitionId);

export const uploadInvoiceAddressList = (files, masterDataConfig: MasterdataConfig) =>
    uploadMasterdataFiles(files, masterDataConfig.invoiceAddressLookupDefinitionId);

export const uploadConversionFactorList = (files, masterDataConfig: MasterdataConfig) =>
    uploadMasterdataFiles(files, masterDataConfig.conversionFactorLookupDefinitionId);

export const uploadContactList = (files, masterDataConfig: MasterdataConfig) =>
    uploadMasterdataFiles(files, masterDataConfig.contactLookupDefinitionId);

export const uploadOrderMatchingList = (files, masterDataConfig: MasterdataConfig) =>
    uploadMasterdataFiles(files, masterDataConfig.orderMatchingLookupDefinitionId);

export const BASE_MASTERDATA_ENTITY = `
    id
    partitionId
    versionId
    lookupDefinitionId
    contentSummaryMultiline
`;

export const CLIENT_MASTERDATA_ENTITY = `
    ${BASE_MASTERDATA_ENTITY}
    clientId1
    clientId2
    clientId3
    clientType
    clientContactPerson1
    companyName
    misc
    address1
    zipCode
    city
    country
    iln
    vat
    locale
    localeNumeric
    internalNote
`;

export const ARTICLE_MASTERDATA_ENTITY = `
    ${BASE_MASTERDATA_ENTITY}
    articleId1
    articleId2
    articleId3
    articlePartitionSpecificNumber
    articleStatus
    description
    orderUnits
    salesUnits
    grossPrice
    variants {
        dimensions {
            name
            values
        }
    }
    orderUnitConversionTarget
    orderUnitConversionFactor
    orderUnitConversionSource
    salesOrganisation
    salesOrganisationOptions
`;

export const ADDRESS_MASTERDATA_ENTITY = `
    ${BASE_MASTERDATA_ENTITY}
    addressId1
    addressId2
    addressId3
    contactPerson
    name
    misc
    address1
    address2
    zipCode
    city
    country
    poBox
    phone
`;

export const FRAMEWORK_CONTRACT_MASTERDATA_ENTITY = `
    ${BASE_MASTERDATA_ENTITY}
    clientId
    clientName
    contractId1
    contractId2
    contractName
`;

export const CONTACT_MASTERDATA_ENTITY = `
    ${BASE_MASTERDATA_ENTITY}
    contactId
    phone
    email
    fullName
    firstName
    lastName
`;

export const ORDER_MATCHING_MASTERDATA_ENTITY = `
    ${BASE_MASTERDATA_ENTITY}
    orderNumber
    orderDate
    deliveryAddress
    receiverNumber
    positionNumber
    articleId1
    articleId2
    articleId3
    articleDescription
    articlePrice
    quantity
    totalPrice
    deliveryDate
    unit
    iban
    swift
    account
    paymentTermsType
    paymentTermsDiscount
    paymentTermsDueDays
`;

export const POLYMORPHIC_MASTERDATA_ENTITY = `
    ... on ClientLookupType {
        ${CLIENT_MASTERDATA_ENTITY}
    }
    ... on ArticleLookupType {
        ${ARTICLE_MASTERDATA_ENTITY}
    }
    ... on AddressLookupType {
        ${ADDRESS_MASTERDATA_ENTITY}
    }
    ... on FrameworkContractLookupType {
        ${FRAMEWORK_CONTRACT_MASTERDATA_ENTITY}
    }
    ... on ContactLookupType {
        ${CONTACT_MASTERDATA_ENTITY}
    }
    ... on OrderMatchingLookupType {
        ${ORDER_MATCHING_MASTERDATA_ENTITY}
    }
`;

// Searchable fields must be included in the response types so that we can display their value. We also want to
// fetch all properties here needed for a bulk-update of related fields (i.e. the full entity). These are included
// as `_lookupEntity` in the update payload.
// The backend still uses _lookup_id to re-do the lookup, _lookupEntity is sent in case the
// backend should not refetch again.
export const SEARCH_MASTERDATA_ENTITY_FIELD = gql`
    query MasterdataEntityFieldAssistanceSearch(
        $lookupDefinitionId: UUID!
        $searchTerm: String!
        $targetField: String!
        $partitionId: String
        $from: Int
        $size: Int
        $kwargs: GenericScalar
    ) {
        assistanceSearchByField(
            lookupDefinitionId: $lookupDefinitionId
            searchTerm: $searchTerm
            targetField: $targetField
            partitionId: $partitionId
            From: $from
            size: $size
            kwargs: $kwargs
        ) {
            edges {
                node {
                    ${POLYMORPHIC_MASTERDATA_ENTITY}
                }
            }
        }
    }
`;
