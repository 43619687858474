import { gql } from '@apollo/client';
import { FULL_CHANNEL } from './pages/Configuration/queries';
import { BASE_FIELD, PRIMITIVE_FIELD, SELECT_FIELD_FRAGMENT, BASE_DOCUMENT } from '../assistance/queries';

const FULL_ORDER_CONFIRMATION = gql`
    ${SELECT_FIELD_FRAGMENT}
    fragment FullOrderConfirmation on OrderConfirmation {
        ${BASE_DOCUMENT}

        supplier {
            name
            customerNumber
            customerType
            misc
            contactPerson
            ${BASE_FIELD}

            address {
                addressId
                name
                postcode
                city
                country
                phone
                email
                companyName
                streetAndNr
                misc
                bbox
                pageIndex
                predictionConfidence
            }
        }
        
        buyer {
            name
            customerNumber
            customerType
            misc
            contactPerson
            ${BASE_FIELD}

            address {
                addressId
                name
                postcode
                city
                country
                phone
                email
                companyName
                streetAndNr
                misc
                bbox
                pageIndex
                predictionConfidence
            }
        }


        documentType {
            ${PRIMITIVE_FIELD}
        }
        deliveryDate {
            ${PRIMITIVE_FIELD}
        }
        orderDate {
            ${PRIMITIVE_FIELD}
        }

        orderNumber {
            ${PRIMITIVE_FIELD}
        }
        totalAmount {
            ${PRIMITIVE_FIELD}
        }
        totalAmountNet {
            ${PRIMITIVE_FIELD}
        }
        orderConfirmationDate {
            ${PRIMITIVE_FIELD}
        }
        orderConfirmationNumber {
            ${PRIMITIVE_FIELD}
        }

        deliveryAddress {
            addressId
            addressId2
            name
            postcode
            city
            country
            phone
            email
            companyName
            streetAndNr
            misc
            ${BASE_FIELD}
        }
        infoText {
            ${PRIMITIVE_FIELD}
        }
        commissionNumber {
            ${PRIMITIVE_FIELD}
        }

        lineItems {
            tableId
            isMatchingIgnored
            ${BASE_FIELD}

            pos {
                ${PRIMITIVE_FIELD}
            }
            articleNumber {
                ${PRIMITIVE_FIELD}
            }
            articleNumber2 {
                ${PRIMITIVE_FIELD}
            }
            articleNumber3 {
                ${PRIMITIVE_FIELD}
            }
            clientArticleNumber {
                ${PRIMITIVE_FIELD}
            }

            articlePartition {
                ${PRIMITIVE_FIELD}
            }
            articlePartitionSpecificNumber {
                ${PRIMITIVE_FIELD}
            }
            articleVariant1 {
                ...FullSelectField
            }
            articleCommissionNumber {
                ${PRIMITIVE_FIELD}
            }
            articleCustomsTariffNumber {
                ${PRIMITIVE_FIELD}
            }
            articleTaxCode {
                ${PRIMITIVE_FIELD}
            }

            articleDeliveryDate {
                ${PRIMITIVE_FIELD}
            }
            articleDiscount {
                ${PRIMITIVE_FIELD}
            }
            articleDiscountAmount {
                ${PRIMITIVE_FIELD}
            }
            articleComments {
                ${PRIMITIVE_FIELD}
            }
            articleDescription {
                ${PRIMITIVE_FIELD}
            }
            articleDescriptionMasterData {
                ${PRIMITIVE_FIELD}
            }
            grossPrice {
                ${PRIMITIVE_FIELD}
            }
            unitPrice {
                ${PRIMITIVE_FIELD}
            }
            priceUnit {
                ...FullSelectField
            }
            totalPrice {
                ${PRIMITIVE_FIELD}
            }
            unit {
                ...FullSelectField
            }
            quantity {
                ${PRIMITIVE_FIELD}
            }
            quantityAndUnit {
                ${PRIMITIVE_FIELD}
            }
            dynamicSchemaSerialized
        }
        dynamicSchemaSerialized
    }
`;

const FULL_RECORD = gql`
    ${FULL_ORDER_CONFIRMATION}
    ${FULL_CHANNEL}
    fragment FullRecord on OrderConfirmationProcessingRecord {
        id
        createdAt
        deletedAt
        deletedBy {
            email
            firstName
            lastName
        }

        sender
        subject

        status

        canFinishAssistance
        canFinishHeaderAssistance
        canFinishLineItemsAssistance

        lockedBy {
            email
        }

        putOnHoldReason
        putOnHoldBy {
            email
            firstName
            lastName
        }

        assistedBy {
            email
            firstName
            lastName
        }

        assistedAt

        stepRun {
            id
            stepName
            manualOnly
            executionStatus
            error
            context
        }

        orderconfirmationprocessingsteprunSet {
            id
            createdAt
            updatedAt
            stepName
            manualOnly
            executionStatus
            context
            error
        }

        channel {
            ...FullChannel
        }

        isDiscarded
        isTestDocument

        discardRecord {
            reason
            user {
                id
                firstName
                lastName
            }
            createdAt
            updatedAt
        }

        deliveryRecord {
            deliveryMethod
            deliveryStatus
            externalReference
            externalMessage
            externalStatus
        }

        sourceFile {
            ... on Email {
                sender {
                    email
                    name
                }
                receivers {
                    email
                    name
                }
                subject
                messageId
                language
                receivedAt

                text
                html
                attachments {
                    cid
                    contentDisposition
                    name
                    type
                    url
                }

                senderClientId
                prediction
                predictionConfidence
                sourceUrl

                predictionConfidences
            }

            ... on File {
                name
                type
                url
            }
        }

        outputFile {
            ... on Export {
                files {
                    name
                    url
                }
            }
        }

        orderConfirmationUnsaved {
            ...FullOrderConfirmation
        }

        labelingStatus

        masterdataVersionsExtracted
        isMatchingEnabled
    }
`;

export const GET_OVERVIEW_DATA = gql`
    query {
        openOrderConfirmationProcessingRecordsCount
        orderConfirmationProcessingChannels {
            id
            name
        }
    }
`;

export const GET_TABLE_OVERVIEW_DATA = gql`
    query ($filters: [TableFilter], $offset: Int, $first: Int, $channelId: UUID) {
        openOrderConfirmationProcessingRecordsCount
        filteredOrderConfirmationProcessingRecords(filters: $filters, offset: $offset, first: $first) {
            totalCount
            edges {
                node {
                    id
                    sender
                    subject
                    status
                    createdAt
                    deletedAt
                    lockedBy {
                        email
                    }
                    stepRun {
                        id
                        manualOnly
                        executionStatus
                        error
                        context
                    }
                    channel {
                        id
                        name

                        extractorConfig {
                            validationChecks
                        }
                    }
                    isDiscarded

                    deliveryRecord {
                        deliveryMethod
                        deliveryStatus
                        externalReference
                        externalMessage
                        externalStatus
                    }
                    orderConfirmationUnsaved {
                        validationChecks {
                            name
                            result
                            targetValue
                            reference
                        }

                        orderNumber {
                            value
                            predictionConfidence
                            confidenceExplanation {
                                translationKey
                                explanationDetails
                            }
                        }
                    }
                    isTestDocument
                    canProcessAutomatically
                }
            }
        }

        openOrderConfirmationProcessingRecordsPerChannelCount(channelId: $channelId)
        orderConfirmationProcessingChannels {
            id
            name
            emailAddress
            team {
                id
                users {
                    email
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const GET_ASSISTANCE_OVERVIEW_DATA = gql`
    query ($recordId: UUID!, $filters: [TableFilter]) {
        orderConfirmationProcessingRecordAssistancePagination(recordId: $recordId, filters: $filters) {
            filteredCount
            position
            previousRecordId
            nextRecordId
        }
    }
`;

export const GET_ASSISTANCE_DATA = gql`
    ${FULL_RECORD}
    ${FULL_ORDER_CONFIRMATION}
    query ($id: UUID!) {
        orderConfirmationProcessingRecord(id: $id) {
            ...FullRecord

            orderConfirmationExtracted {
                ...FullOrderConfirmation
            }

            ocr {
                pagesCount
                processedFile {
                    url
                }
            }
        }
    }
`;

export const UPDATE_PROCESSING_RECORD = gql`
    mutation ($recordId: UUID!, $fieldName: String, $itemIndex: Int, $action: String!, $payload: GenericScalar) {
        orderConfirmationUpdate(
            recordId: $recordId
            fieldName: $fieldName
            itemIndex: $itemIndex
            action: $action
            payload: $payload
        ) {
            record {
                canFinishAssistance
                canFinishHeaderAssistance
                canFinishLineItemsAssistance
            }

            patch {
                op
                path
                from
                value
            }
        }
    }
`;

export const RESELECT_PROCESSING_RECORD = gql`
    mutation ($recordId: UUID!, $fieldName: String, $bbox: [[Float]]!, $pageIndex: Int!, $itemIndex: Int) {
        orderConfirmationReselect(
            recordId: $recordId
            fieldName: $fieldName
            bbox: $bbox
            pageIndex: $pageIndex
            itemIndex: $itemIndex
        ) {
            record {
                canFinishAssistance
                canFinishHeaderAssistance
                canFinishLineItemsAssistance
            }

            patch {
                op
                path
                from
                value
            }
        }
    }
`;

export const ORDER_CONFIRMATION_EXTRACT_TEXT = gql`
    mutation OrderConfirmationExtractText($recordId: UUID!, $pageIndex: Int!, $bbox: [[Float]]!) {
        orderConfirmationExtractText(recordId: $recordId, pageIndex: $pageIndex, bbox: $bbox) {
            bbox
            text
        }
    }
`;

export const DELETE_ORDER_CONFIRMATION = gql`
    mutation ($recordId: UUID!) {
        orderConfirmationDelete(recordId: $recordId) {
            success
        }
    }
`;

export const RETRY_STEP = gql`
    mutation ($recordId: UUID!, $stepRunId: UUID!) {
        orderConfirmationRetryStep(recordId: $recordId, stepRunId: $stepRunId) {
            success
        }
    }
`;

export const DISCARD_ORDER_CONFIRMATION = gql`
    mutation ($recordId: UUID!, $reason: String) {
        orderConfirmationDiscard(recordId: $recordId, reason: $reason) {
            success
        }
    }
`;

export const REOPEN_FOR_ASSISTANCE = gql`
    mutation ($recordId: UUID!, $reason: String) {
        orderConfirmationReopenForAssistance(recordId: $recordId, reason: $reason) {
            success
        }
    }
`;

export const UPLOAD_FILE = gql`
    mutation ($channelId: UUID!, $files: [Upload]!, $batchId: String, $isTestingDocument: Boolean!) {
        orderConfirmationUploadFile(
            input: { channelId: $channelId, files: $files, batchId: $batchId, isTestingDocument: $isTestingDocument }
        ) {
            records {
                id
            }
            success
        }
    }
`;

export const RE_UPLOAD_FILE = gql`
    mutation ($channelId: UUID!, $files: [Upload]!, $recordId: String!, $isTestingDocument: Boolean!) {
        orderConfirmationUploadFile(
            input: { channelId: $channelId, files: $files, recordId: $recordId, isTestingDocument: $isTestingDocument }
        ) {
            records {
                id
            }
            success
        }
    }
`;

export const SEND_TO_LABELING = gql`
    mutation ($recordId: UUID!) {
        orderConfirmationSendToLabeling(recordId: $recordId) {
            success
        }
    }
`;

export const GET_NEXT_ASSISTANCE_RECORD = gql`
    query ($filters: [TableFilter], $channelId: UUID) {
        nextOrderConfirmationProcessingRecord(filters: $filters, channelId: $channelId) {
            id
        }
    }
`;

export const CREATE_CHANNEL = gql`
    mutation ($name: String!, $fromExistingChannelId: UUID) {
        createOrderConfirmationProcessingChannel(name: $name, fromExistingChannelId: $fromExistingChannelId) {
            channel {
                id
            }
            success
        }
    }
`;

export const GET_CUSTOMER_AUTOMATION_RATIO = gql`
    query ($startDate: Date!, $endDate: Date!) {
        customerOrderConfirmationAutomationRatio(startDate: $startDate, endDate: $endDate) {
            automationDays {
                date
                assistedCount
                automaticCount
                totalCount
                automationRatio
            }
            last5DaysAutomationRatio
        }
    }
`;

export const GET_CHANNEL_AUTOMATION_RATIO = gql`
    query ($channelId: UUID!, $startDate: Date!, $endDate: Date!) {
        channelOrderConfirmationAutomationRatio(channelId: $channelId, startDate: $startDate, endDate: $endDate) {
            automationDays {
                date
                assistedCount
                automaticCount
                totalCount
                automationRatio
            }
            last5DaysAutomationRatio
        }
    }
`;

export const GET_ALL_CHANNELS = gql`
    ${FULL_CHANNEL}
    query {
        orderConfirmationProcessingChannels {
            ...FullChannel
        }
    }
`;
