export const LOGIN_PATH = '/login';
export const PROVIDER_LOGIN_PATH = '/login/provider';
export const PROVIDER_LOGIN_PROVIDER_PATH = '/login/providers/:provider';
export const PROVIDER_AUTHORIZED_PATH = '/login/providers/:provider/authorized';
export const FORGOT_PATH = '/reset_password/';
export const RESET_PATH = '/reset_password/user/:userId/token/:token';
export const SIGNUP_PATH = '/signup';

export const COPYRIGHT_URL = 'https://workist.com';
export const IMPRINT_URL = 'https://workist.com/impressum/';
export const PRIVACY_URL = 'https://workist.com/datenschutzerklaerung/';
