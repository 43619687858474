import DocumentAssistance, {
    TABS_OPTIONS,
    ALL_TABS,
    camelCaseFieldName,
} from '../../../generic_document/pages/Assistance';
import {
    DELETE_PROPERTY_BILL,
    DISCARD_PROPERTY_BILL,
    GET_ASSISTANCE_OVERVIEW_DATA,
    GET_NEXT_ASSISTANCE_RECORD,
    RETRY_STEP,
    SEND_TO_LABELING,
} from '../../queries';
import {
    ASSISTANCE_PATH,
    ASSISTANCE_TAB_PATH,
    CHANNEL_FINISHED_PATH,
    CHANNEL_PATH,
    CHANNEL_TESTING_PATH,
    OVERVIEW_FINISHED_PATH,
    OVERVIEW_PATH,
    OVERVIEW_TESTING_PATH,
} from '../../constants';
import { getHeaderFieldConfigs, getLineItemFieldConfigs } from './utils';
import { useMemo } from 'react';
import { filterValidation } from '../../../assistance/utils';
import { useApolloClient } from '@apollo/client';
import ItemsView from '../../../assistance/containers/ItemsView';
import AssistanceFinishButton from '../../../assistance/containers/AssistanceFinishButton';
import * as React from 'react';
import AssistanceNextButton from '../../../assistance/containers/AssistanceNextButton';
import { url } from '../../../core/utils/link';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../utils/string';
import MenuItem from '../../../core/components/MenuItem';
import HeaderView from '../../../assistance/containers/HeaderView';
import { EXTERNAL_STATUS_FILTER_CHOICES } from '../../../assistance/containers/Overview';
import Button from '../../../core/components/Button';
import { DocumentType } from '../../../generic_document/constants';
import { RE_UPLOAD_FILE } from '../../queries';
import {
    getCustomerFieldConditionalComponents,
    getCustomerFieldGroupConditionalComponents,
} from '../../../generic_document/components/CustomerField';
import { getAddressFieldConditionalComponents } from '../../../generic_document/components/AddressField';
import { ArticleNumberFieldConditionalComponents } from '../../../generic_document/components/ArticleNumberField';

const createHeaderTab = ({ data, user, document, recordId, record, loading, readOnly, documentConfig, handlers }) => {
    const { t, i18n } = useTranslation('assistance');

    const ocr = data?.[documentConfig?.documentTypeName + 'ProcessingRecord']?.ocr;
    const canFinishHeaderAssistance = record?.canFinishHeaderAssistance;
    const headerValidationChecks = useMemo(
        () =>
            filterValidation(document?.validationChecks, i18n).filter(
                (check) => !check?.reference?.startsWith('table_id=')
            ),
        [record]
    );

    const config = record?.channel?.config;
    const headerFields = useMemo(() => (config?.headerFields || []).map(camelCaseFieldName), [record]);
    const optionalHeaderFields = useMemo(() => (config?.optionalHeaderFields || []).map(camelCaseFieldName), [record]);

    const client = useApolloClient();
    const customerNumber = document?.customer?.customerNumber;
    const masterDataConfig = record?.channel?.masterDataConfig;
    const headerFieldConfigs = useMemo(
        () => documentConfig.getHeaderFieldConfigs(client, record?.id, masterDataConfig, headerFields),
        [client, record?.id, customerNumber, masterDataConfig]
    );

    const firstItemsTab = documentConfig?.firstItemsTab || TABS_OPTIONS.Items;

    const manualCaptureItem = (
        <MenuItem
            label={t('nextButton.manualCaptureButton')}
            onClick={() =>
                handlers.onUpdate({
                    action: 'action:manual_capture',
                    payload: {
                        externalMessage: '',
                    },
                })
            }
        />
    );

    const manualCapturedButton = (
        <Button
            label={t('nextButton.manualCapturedButton')}
            onClick={() =>
                handlers.onUpdate({
                    action: 'action:captured_manually',
                    payload: {
                        stepRunId: record.stepRun.id,
                    },
                })
            }
        />
    );

    const defaultButton = (
        <AssistanceNextButton
            disabled={!canFinishHeaderAssistance}
            label={t('headerView.continueButton')}
            onClick={() => handlers.onUpdate({ action: 'action:mark_header_correct' })}
            linkTo={recordId && url(documentConfig.ASSISTANCE_TAB_PATH, { recordId, tab: firstItemsTab })}
            onReset={handlers.onReset}
            onDiscard={handlers.onDiscard}
            extraItems={[manualCaptureItem]}
        />
    );

    return (
        <HeaderView
            user={user}
            recordId={recordId}
            record={record}
            ocr={ocr}
            document={document}
            onUpdate={handlers.onUpdate}
            onReselect={handlers.onReselect}
            loading={loading}
            readOnly={readOnly}
            headerFields={headerFields}
            optionalHeaderFields={optionalHeaderFields}
            headerValidationChecks={headerValidationChecks}
            fieldConfigs={headerFieldConfigs}
            formButtons={
                record?.deliveryRecord?.externalStatus == EXTERNAL_STATUS_FILTER_CHOICES.ENTER_MANUALLY_API_STATUS
                    ? manualCapturedButton
                    : defaultButton
            }
        />
    );
};

const createItemsTab = ({
    user,
    data,
    document,
    recordId,
    record,
    loading,
    readOnly,
    documentConfig,
    handlers,
    explanationToggle,
    itemType,
    nextTab = null,
}) => {
    const { t, i18n } = useTranslation('assistance');
    const lineItemFieldsName = itemType + 'Columns';
    const optionalLineItemFieldsName = 'optional' + capitalizeFirstLetter(itemType) + 'Columns';

    const ocr = data?.[documentConfig?.documentTypeName + 'ProcessingRecord']?.ocr;

    const config = record?.channel?.config;
    const lineItemFields = useMemo(() => (config?.[lineItemFieldsName] || []).map(camelCaseFieldName), [record]);
    const optionalLineItemFields = useMemo(
        () => (config?.[optionalLineItemFieldsName] || []).map(camelCaseFieldName),
        [record]
    );

    // there is no concept of tables anymore - we just show them at the top all together
    const lineItemValidationChecks = useMemo(
        () =>
            filterValidation(document?.validationChecks, i18n).filter((check) =>
                check?.reference?.startsWith('table_id=')
            ),
        [record]
    );

    const client = useApolloClient();
    const masterDataConfig = record?.channel?.masterDataConfig;
    const lineItemFieldConfigs = useMemo(
        () => documentConfig.getLineItemFieldConfigs(client, record?.id, masterDataConfig),
        [client, record?.id, masterDataConfig]
    );

    const canFinishAssistance = record?.canFinishAssistance;

    const manualCaptureItem = (
        <MenuItem
            label={t('nextButton.manualCaptureButton')}
            onClick={() =>
                handlers.onUpdate({
                    action: 'action:manual_capture',
                    payload: {
                        externalMessage: '',
                    },
                })
            }
        />
    );

    const manualCapturedButton = (
        <Button
            label={t('nextButton.manualCapturedButton')}
            onClick={() =>
                handlers.onUpdate({
                    action: 'action:captured_manually',
                    payload: {
                        stepRunId: record.stepRun.id,
                    },
                })
            }
        />
    );

    const defaultButtons = nextTab ? (
        <AssistanceNextButton
            disabled={!record?.['canFinish' + capitalizeFirstLetter(itemType) + 'Assistance']}
            label={t('headerView.continueButton')}
            onClick={() => handlers.onUpdate({ action: 'action:mark_header_correct' })}
            linkTo={recordId && url(documentConfig.ASSISTANCE_TAB_PATH, { recordId, tab: nextTab })}
            onReset={handlers.onReset}
            onDiscard={handlers.onDiscard}
            extraItems={[manualCaptureItem]}
        />
    ) : (
        <AssistanceFinishButton
            disabled={!canFinishAssistance}
            onReset={handlers.onReset}
            onDiscard={handlers.onDiscard}
            onFinish={handlers.onFinish}
            onFinishAndContinue={handlers.onFinishAndContinue}
            extraItems={[manualCaptureItem]}
        />
    );

    return (
        <ItemsView
            user={user}
            recordId={recordId}
            record={record}
            ocr={ocr}
            document={document}
            items={document?.[itemType]}
            itemType={itemType}
            onUpdate={(props) => handlers.onUpdate({ ...props, itemType: itemType })}
            onReselect={(props) => handlers.onReselect({ ...props, itemType: itemType })}
            loading={loading}
            readOnly={readOnly}
            itemFields={lineItemFields}
            optionalItemFields={optionalLineItemFields}
            itemValidationChecks={lineItemValidationChecks}
            fieldConfigs={lineItemFieldConfigs}
            formButtons={
                record?.deliveryRecord?.externalStatus == EXTERNAL_STATUS_FILTER_CHOICES.ENTER_MANUALLY_API_STATUS
                    ? manualCapturedButton
                    : defaultButtons
            }
            enableAddItem={true}
            onAddItemClick={() => handlers.onUpdate({ action: 'action:add_empty_item', itemType: itemType })}
            explanationToggle={explanationToggle}
        />
    );
};

const PropertyBillAssistance = (props) => {
    const { t } = useTranslation('assistance');

    const documentConfiguration = useMemo(
        () => ({
            documentType: DocumentType.PropertyBill,
            documentTypeName: 'propertyBill',
            GET_ASSISTANCE_OVERVIEW_DATA: GET_ASSISTANCE_OVERVIEW_DATA,
            GET_NEXT_ASSISTANCE_RECORD: GET_NEXT_ASSISTANCE_RECORD,
            RETRY_STEP: RETRY_STEP,
            DISCARD: DISCARD_PROPERTY_BILL,
            DELETE: DELETE_PROPERTY_BILL,
            ASSISTANCE_PATH: ASSISTANCE_PATH,
            ASSISTANCE_TAB_PATH: ASSISTANCE_TAB_PATH,
            OVERVIEW_PATH: OVERVIEW_PATH,
            OVERVIEW_FINISHED_PATH: OVERVIEW_FINISHED_PATH,
            OVERVIEW_TESTING_PATH: OVERVIEW_TESTING_PATH,
            CHANNEL_PATH: CHANNEL_PATH,
            CHANNEL_FINISHED_PATH: CHANNEL_FINISHED_PATH,
            CHANNEL_TESTING_PATH: CHANNEL_TESTING_PATH,
            RE_UPLOAD_FILE: RE_UPLOAD_FILE,
            SEND_TO_LABELING: SEND_TO_LABELING,
            getHeaderFieldConfigs: getHeaderFieldConfigs,
            getLineItemFieldConfigs: getLineItemFieldConfigs,
            itemTypes: ['objekte', 'spitzabrechnungen', 'abschlagszahlungen'],
            components: {
                Field: [...getCustomerFieldConditionalComponents('kreditor')],
                HeaderDataFieldGroup: [...getCustomerFieldGroupConditionalComponents('kreditor')],
            },
            tabs: {
                [TABS_OPTIONS.Source]: ALL_TABS[TABS_OPTIONS.Source],
                [TABS_OPTIONS.Document]: ALL_TABS[TABS_OPTIONS.Document],
                [TABS_OPTIONS.Header]: createHeaderTab,
                objekte: (props) => {
                    return createItemsTab({
                        ...props,
                        itemType: 'objekte',
                        nextTab: 'spitzabrechnungen',
                    });
                },
                spitzabrechnungen: (props) => {
                    return createItemsTab({
                        ...props,
                        itemType: 'spitzabrechnungen',
                        nextTab: 'abschlagszahlungen',
                    });
                },
                abschlagszahlungen: (props) => {
                    return createItemsTab({
                        ...props,
                        itemType: 'abschlagszahlungen',
                    });
                },
            },
            defaultTab: TABS_OPTIONS.Document,
            onAddLineItem: ({ onFieldAction, itemIndex, itemType }) => {
                onFieldAction({
                    action: 'action:add_empty_item',
                    itemIndex,
                    itemType,
                });
            },
            firstItemsTab: 'objekte',
            getAlert: ({ record }) =>
                (record?.deliveryRecord?.externalStatus ===
                    EXTERNAL_STATUS_FILTER_CHOICES.ENTER_MANUALLY_API_STATUS && {
                    title: t('header.alerts.manualCapture.title'),
                    message: record?.deliveryRecord?.externalMessage
                        ? t('header.alerts.manualCapture.message', {
                              message: record?.deliveryRecord?.externalMessage || '',
                          })
                        : t('header.alerts.manualCapture.messageWithoutMessage'),
                    severity: 'info',
                }) ||
                (record?.deliveryRecord?.externalStatus === EXTERNAL_STATUS_FILTER_CHOICES.INCOMPLETE_API_STATUS && {
                    title: t('header.alerts.incomplete.title'),
                    message: record?.deliveryRecord?.externalMessage
                        ? t('header.alerts.incomplete.message', {
                              message: record?.deliveryRecord?.externalMessage || '',
                          })
                        : t('header.alerts.incomplete.messageWithoutMessage'),
                    severity: 'info',
                }),
        }),
        []
    );

    return <DocumentAssistance documentConfiguration={documentConfiguration} props={props} />;
};

export default PropertyBillAssistance;
