import { useTranslation } from 'react-i18next';
import classnames from '../../core_updated/utils/classnames';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { AssistanceField } from '../../generic_document/pages/Assistance/AssistanceField';
import AssistanceSection from '../../generic_document/pages/Assistance/AssistanceSection';
import Button from '../../core_updated/components/Button';
import { faCheckCircle, faGripDotsVertical, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { withIcon } from '../../core_updated/components/Icon';
import {
    FormField,
    FormFieldConfig,
    getFormFieldGroups,
    useDocumentConfig,
} from '../../generic_document/pages/Assistance/useDocumentConfig';
import { useDrag, useDrop } from 'react-dnd';
import { useAssistanceContext } from '../../generic_document/pages/Assistance/AssistanceContext';
import Suggestions, { ISuggestion } from './Suggestions';
import CompleteButton from './CompleteButton';

interface IFieldData {
    value?: string;
    predictionConfidence?: number;
    confidenceExplanation?: {
        translationKey: string;
        explanationDetails?: {
            confidence: number;
            field_value: string;
        };
    };
    choices?: string[]; // list of options comes from extracted data
    // ... and more but not sure if it's needed here
    [key: string]: any;
}

export interface IArticle {
    number: IFieldData;
    description: IFieldData;
    quantity: IFieldData;
    unit: IFieldData;
    suggestions?: ISuggestion[];
}

export const DRAGGABLE_ITEM_TYPE = 'POSITION_ARTICLE_GROUP_CARD';

const AddIcon = withIcon(faPlus);
const RemoveIcon = withIcon(faTrash);
const CompleteIcon = withIcon(faCheckCircle);
const DragIcon = withIcon(faGripDotsVertical);

const ArticleAssistanceField = ({
    fieldName,
    valueKey = 'value',
    value,
    placeholder = '',
    // event handler
    onUpdate = undefined,
    data = {},
    // config stuff
    config,
    generalConfig,
}: {
    fieldName: string;
    valueKey?: string;
    value: string;
    placeholder?: string;
    // event handler
    onUpdate?: (value: string) => Promise<any> | void;
    data?: IFieldData;
    // config stuff
    config?: FormFieldConfig;
    generalConfig?: any;
}) => {
    const { readOnly, loading: documentLoading } = useAssistanceContext();

    const [isUpdateActive, setIsUpdateActive] = useState(false);
    const [isFocusActive, setIsFocusActive] = useState(false);

    const handleUpdate = ({ payload }) => {
        setIsUpdateActive(true);
        Promise.resolve(onUpdate?.(payload)).finally(() => {
            setIsUpdateActive(false);
        });
    };

    const handleFocus = () => {
        setIsFocusActive(true);
    };

    const handleBlur = () => {
        setIsFocusActive(false);
    };

    return (
        <AssistanceField
            fieldName={fieldName}
            valueKey={valueKey}
            value={value}
            placeholder={placeholder}
            data={data}
            config={config}
            generalConfig={generalConfig}
            onUpdate={handleUpdate}
            onFocus={handleFocus}
            onBlur={handleBlur}
            isUpdateActive={isUpdateActive}
            isFocusActive={isFocusActive}
            documentReadOnly={readOnly}
            documentLoading={documentLoading}
        />
    );
};

const ArticleForm = ({
    className,
    articleIndex,
    article,
    onArticleChange,
    onRemove,
    fieldConfigs,
    generalConfig,
}: {
    className?: string;
    articleIndex: number;
    article: IArticle;
    onArticleChange: (fieldName: string, payload: any) => void;
    onRemove?: () => void;
    fieldConfigs: any;
    generalConfig: any;
}) => {
    const { readOnly, loading } = useAssistanceContext();

    const { t } = useTranslation('assistance');

    const fieldGroups = getFormFieldGroups(article, fieldConfigs, 'positions');

    return (
        <AssistanceSection.FieldGroup
            title={t('listOfServices.articleSetCard.formTitle', { number: articleIndex + 1 })}
            controls={
                onRemove && (
                    <AssistanceSection.FieldGroupIconButton
                        disabled={readOnly || loading}
                        onClick={onRemove}
                        className="hover:text-error hover:bg-error"
                    >
                        <RemoveIcon />
                    </AssistanceSection.FieldGroupIconButton>
                )
            }
            className={className}
        >
            {fieldGroups.map((fieldGroup: FormField[]) => {
                const formField = fieldGroup[0];
                return (
                    <AssistanceSection.FieldLabel
                        key={formField.fieldName}
                        label={t(`listOfServices.articleSetCard.fieldNames.${formField.fieldName}`)}
                        required={formField.config.isRequired}
                    >
                        <ArticleAssistanceField
                            fieldName={formField.fieldName}
                            value={formField.value}
                            onUpdate={(payload) => {
                                return onArticleChange(formField.fieldName, payload);
                            }}
                            placeholder={t(`listOfServices.articleSetCard.fieldNames.${formField.fieldName}`)}
                            data={formField.data}
                            config={formField.config}
                            generalConfig={generalConfig}
                        />
                    </AssistanceSection.FieldLabel>
                );
            })}
        </AssistanceSection.FieldGroup>
    );
};

export const Separator = ({
    className,
    borderClassName,
    children,
}: {
    className?: string;
    borderClassName?: string;
    children?: React.ReactNode;
}) => {
    return (
        <div className={classnames('relative flex items-center w-full', className)}>
            <div className={classnames('border-b border-solid border-primary flex-1', borderClassName)} />

            {children && <div className="text-secondary text-sm px-4 -mt-0.5">{children}</div>}

            <div className={classnames('border-b border-solid border-primary flex-1', borderClassName)} />
        </div>
    );
};

const useDragAndDrop = ({
    index,
    onDragStart,
    onMove,
    onDrop,
    acceptedDropKey,
}: {
    index: number;
    onDragStart?: any;
    onMove?: any;
    onDrop?: any;
    acceptedDropKey?: string;
}) => {
    const dragRef = useRef(null);
    const previewRef = useRef(null);
    const dropRef = useRef(null);

    const [, drop] = useDrop({
        accept: acceptedDropKey || DRAGGABLE_ITEM_TYPE,
        hover(item: any, monitor) {
            if (!dragRef.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;

            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                onMove?.(null);
                return;
            } else if (hoverClientY < hoverMiddleY) {
                // upper half
                onMove?.(hoverIndex);
                return;
            } else {
                // lower half
                onMove?.(hoverIndex + 1);
                return;
            }
        },
    });

    const [{ isDragging }, drag, dragPreview] = useDrag({
        type: acceptedDropKey || DRAGGABLE_ITEM_TYPE,
        item: () => {
            const item = { index };
            onDragStart?.(item);
            return item;
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
        end: (item, monitor) => {
            onDrop?.(item);
        },
    });

    drag(dragRef);
    drop(dragPreview(previewRef));
    drop(dropRef);

    return { dragRef, previewRef, dropRef, isDragging, drag, drop };
};

export const DropPreview = ({
    className,
    index,
    acceptedDropKey,
}: {
    className?: string;
    index: number;
    acceptedDropKey?: string;
}) => {
    const { dropRef } = useDragAndDrop({ index, acceptedDropKey });
    return <div className={classnames('bg-tertiary rounded-lg h-8', className)} ref={dropRef} />;
};

const PositionArticleSetCard = ({
    className,
    articles,
    onArticlesChange,
    isComplete,
    onIsCompleteChange,
    documentConfig,
    setIndex = 0,
    showArticles,

    onDragStart,
    onMove,
    onDrop,
    acceptedDropKey,
}: {
    className?: string;
    articles: IArticle[];
    onArticlesChange: (action: string, payload?: any) => void;
    isComplete: boolean;
    onIsCompleteChange: (isComplete: boolean) => void;
    documentConfig: any;
    setIndex: number;
    showArticles?: boolean;

    onDragStart?: any;
    onMove?: any;
    onDrop?: any;
    acceptedDropKey?: string;
}) => {
    const { readOnly, loading } = useAssistanceContext();

    const { t } = useTranslation('assistance');

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(showArticles);
    }, [showArticles]);

    const { generalConfig, fieldConfigsByPrefix } = useDocumentConfig(documentConfig, ['positions']);
    const articleFieldConfigs = fieldConfigsByPrefix['positions'];

    const handleIsCompleteChange = (isComplete: boolean) => {
        setIsOpen(isArticleSetEmpty);
        return onIsCompleteChange(isComplete);
    };

    // drag and drop
    const { dragRef, dropRef, isDragging } = useDragAndDrop({
        index: setIndex,
        onDragStart,
        onMove,
        onDrop,
        acceptedDropKey,
    });

    const articleNumbers = articles.map((article) => article.number.value);

    const getIsArticleEmpty = (article: IArticle) => {
        return Object.values(article).every((field) => !field?.value);
    };

    const lastArticle = articles[articles.length - 1];
    const isLastArticleEmpty = getIsArticleEmpty(lastArticle);
    const isArticleSetEmpty = articles.every((article) => getIsArticleEmpty(article));

    const showAddArticleButton = !isLastArticleEmpty;
    const showSuggestions = isLastArticleEmpty;

    const handleSelectSuggestion = (articleIndex: number, suggestion: ISuggestion) => {
        return onArticlesChange('action:change_article_field', { articleIndex, setIndex, _lookupEntity: suggestion });
    };

    return (
        <div ref={dropRef} className={classnames(isDragging && 'bg-secondary rounded-lg', className)}>
            <div
                className={classnames(
                    'flex flex-col rounded-lg shadow-md bg-primary border border-primary border-solid overflow-hidden',
                    isDragging && 'outline outline-brand outline-2'
                )}
            >
                <AssistanceSection
                    title={t('listOfServices.articleSetCard.title', { articles: articleNumbers.join(', ') })}
                    controls={
                        <>
                            <CompleteButton isComplete={isComplete} onIsCompleteChange={handleIsCompleteChange} />
                            <div
                                ref={dragRef}
                                className="flex items-center justify-center rounded transition-colors duration-200 h-8 hover:bg-secondary cursor-move px-2"
                            >
                                {!readOnly && <DragIcon />}
                            </div>
                        </>
                    }
                    open={isOpen}
                    onOpenChange={setIsOpen}
                >
                    {isComplete && isArticleSetEmpty ? (
                        <div className="flex flex-col items-center justify-center p-4 rounded-lg bg-secondary text-sm">
                            <span className="p-3">{t('listOfServices.articleSetCard.completedWithoutArticles')}</span>
                            <Button
                                variant="ghost"
                                onClick={() => onIsCompleteChange(false)}
                                className="flex gap-2 items-center text-brand hover:text-brand-hover"
                            >
                                <AddIcon />
                                {t('itemsView.actions.addArticle')}
                            </Button>
                        </div>
                    ) : (
                        <div className="flex flex-col gap-8">
                            {articles?.map((article, index) => (
                                <>
                                    <ArticleForm
                                        // key has to be unique so if one group is deleted it doesn't
                                        // look like the one below triggered an update on it
                                        key={`article-${index}-${article.number.value}`}
                                        articleIndex={index}
                                        article={article}
                                        onArticleChange={(fieldName, payload) => {
                                            return onArticlesChange('action:change_article_field', {
                                                articleIndex: index,
                                                fieldName: fieldName,
                                                ...payload, // Can be only a value or also a lookupEntity
                                            });
                                        }}
                                        onRemove={
                                            setIndex !== 0 || (articles && articles.length > 1)
                                                ? () =>
                                                      onArticlesChange('action:remove_article', { articleIndex: index })
                                                : undefined
                                        }
                                        fieldConfigs={articleFieldConfigs}
                                        generalConfig={generalConfig}
                                    />

                                    {index < articles.length - 1 && (
                                        <Separator>{t('listOfServices.articleSetCard.and')}</Separator>
                                    )}
                                </>
                            ))}

                            {showAddArticleButton && (
                                <div className="flex justify-center">
                                    <Button
                                        disabled={readOnly || loading}
                                        variant="ghost"
                                        onClick={() => onArticlesChange('action:add_article')}
                                    >
                                        <AddIcon /> {t('listOfServices.articleSetCard.addArticle')}
                                    </Button>
                                </div>
                            )}

                            {showSuggestions && (
                                <Suggestions
                                    suggestions={articles[0]?.suggestions || []}
                                    onSelect={(suggestion) => handleSelectSuggestion(articles.length - 1, suggestion)}
                                />
                            )}
                        </div>
                    )}
                </AssistanceSection>
            </div>
        </div>
    );
};

export default PositionArticleSetCard;
