import * as React from 'react';
import { type KeyboardEvent, useRef, useState } from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import classnames from '../../utils/classnames';
import { withIcon } from '../Icon';
import { faCheck, faChevronUp, faCircleNotch, faChevronDown, faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { useControllableState } from '../../utils/useControllableState';
import Field, { FieldProps } from './Field';

export interface Option {
    label: string;
    value: string;
    role?: string;
    description?: string;
    disabled?: boolean;
    [key: string]: any;
}

interface SelectProps
    extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'value' | 'defaultValue'>,
        FieldProps {
    options: Option[];
    renderOption?: (option: Option, value: string) => React.ReactNode;
    emptyMessage?: string;
    isLoading?: boolean;
    disabled?: boolean;
    placeholder?: string;
    readOnly?: boolean;
}

const CheckIcon = withIcon(faCheck);
const ChevronUpIcon = withIcon(faChevronUp);
const ChevronDownIcon = withIcon(faChevronDown);
const LoadingCircleIcon = withIcon(faCircleNotch);

const defaultRenderOption = (option: Option) => (
    <span>
        <span className="text-primary">{option.label}</span>
        {option.label !== option.value ? <span className="block text-xs text-tertiary">{option.value}</span> : null}
        {option.description ? <span className="block text-xs text-tertiary">{option.description}</span> : null}
    </span>
);

const SelectField = React.forwardRef(
    (
        {
            defaultValue,
            value: propsValue,
            onValueChange,
            onFocus,
            onBlur,
            readOnly,
            disabled,
            controls,
            className,
            inputRef = undefined,
            // field specific props
            options = [],
            renderOption = defaultRenderOption,
            isLoading = false,
            ...props
        }: SelectProps,
        ref: any
    ) => {
        const [isOpen, setOpen] = useState(false);
        const [value, setValue] = useControllableState(defaultValue, propsValue, onValueChange);
        const selectedOption = options.find((option) => option.value === value);

        const handleOpenChange = (open: boolean) => {
            setOpen(open);
            if (!open) onBlur?.(null);
            else onFocus?.(null);
        };

        return (
            <SelectPrimitive.Root value={value} onValueChange={setValue} open={isOpen} onOpenChange={handleOpenChange}>
                <Field
                    className={classnames('shadow-sm group', className)}
                    readOnly={readOnly}
                    disabled={disabled}
                    ref={ref}
                >
                    <Field.Input>
                        <SelectPrimitive.Trigger
                            className="px-2 py-1.5 flex-1 min-w-0 justify-between items-center flex bg-transparent text-left min-h-8 relative"
                            disabled={disabled || readOnly}
                            {...props}
                            ref={inputRef}
                        >
                            <div className="absolute inset-0 opacity-0 text-primary">
                                <SelectPrimitive.Value />
                            </div>

                            {value ? (
                                selectedOption?.label || value
                            ) : (
                                <span className="text-tertiary">{props.placeholder}</span>
                            )}
                        </SelectPrimitive.Trigger>
                    </Field.Input>
                    <Field.Controls>
                        {!readOnly && (
                            <Field.ControlButton className="group-hover:opacity-100 point !bg-transparent">
                                {isLoading ? (
                                    <LoadingCircleIcon spin />
                                ) : (
                                    <ChevronDownIcon onClick={() => setOpen(!isOpen)} />
                                )}
                            </Field.ControlButton>
                        )}
                        {controls}
                    </Field.Controls>
                </Field>

                <SelectPrimitive.Portal>
                    <SelectPrimitive.Content
                        className="p-1 z-[20000] w-full max-h-96  overflow-hidden rounded-md border bg-primary shadow-md outline-none animate-in fade-in-0 zoom-in-95"
                        position="popper"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <SelectPrimitive.ScrollUpButton className="text-center text-secondary hover:text-primary py-1.5 px-2 hover:bg-secondary focus:bg-secondary">
                            <ChevronUpIcon />
                        </SelectPrimitive.ScrollUpButton>
                        <SelectPrimitive.Viewport>
                            {options.map((option: Option, i: number) => (
                                <SelectPrimitive.Item
                                    value={option.value}
                                    key={i}
                                    disabled={option.disabled}
                                    className={classnames(
                                        'flex items-center gap-2 w-full',
                                        'relative flex w-full cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-4 text-sm outline-none hover:bg-secondary focus:bg-secondary aria-selected:bg-brand aria-selected:text-neutral-900 focus:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
                                    )}
                                >
                                    <SelectPrimitive.ItemText>{renderOption(option, value)}</SelectPrimitive.ItemText>
                                    <SelectPrimitive.ItemIndicator className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center text-primary">
                                        <CheckIcon className="h-4 w-4" />
                                    </SelectPrimitive.ItemIndicator>
                                </SelectPrimitive.Item>
                            ))}
                        </SelectPrimitive.Viewport>
                        <SelectPrimitive.ScrollDownButton className="text-center text-secondary hover:text-primary py-1.5 px-2 hover:bg-secondary focus:bg-secondary">
                            <ChevronDownIcon />
                        </SelectPrimitive.ScrollDownButton>
                    </SelectPrimitive.Content>
                </SelectPrimitive.Portal>
            </SelectPrimitive.Root>
        );
    }
);
export default SelectField;
