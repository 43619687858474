import * as React from 'react';
import classnames from 'classnames';

import { PRIVACY_URL, COPYRIGHT_URL, IMPRINT_URL } from '../../constants';

import './style.scss';

interface IProps {
    children: any;
    className?: string;
}

const LoginWrapper = (props: IProps) => {
    const { children, className } = props;
    return (
        <main className={classnames('login', className)}>
            <div className="login__card-wrapper">
                <div className="login__card-fly-in-wrapper">{children}</div>
            </div>

            <footer className="login__footer">
                <a href={COPYRIGHT_URL}>&copy; {new Date().getFullYear()} workist &trade;</a>
                <span>
                    <a href={IMPRINT_URL}>Impressum</a>
                    <a href={PRIVACY_URL}>Datenschutz</a>
                </span>
            </footer>
        </main>
    );
};

export default LoginWrapper;
